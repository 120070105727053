import styled from 'styled-components';
import ArrowSlider from '../ArrowSlider';
import Label, { Props as LabelProps } from '../Label';

interface Props {
  labels: LabelProps[];
}

const LabelList = ({ labels }: Props) => {
  return (
    <Wrapper>
      <div className={"labels-list__labels"}>
        {labels.map((label: LabelProps) => {
            return <Label key={label.text} {...label} />;
          })
        }
      </div>
      <div className={"labels-list__controllers"}>
        <ArrowSlider type='left' />
        <ArrowSlider type='right' />
      </div>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  
  .labels-list__labels {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    gap: 8px;
    position: relative;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .labels-list__controllers { 
    flex: 1 0 auto;
    width: fit-content;
  }
`;

export default LabelList;