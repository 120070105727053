import React, {Suspense} from "react";

import {
    Routes, // instead of "Switch"
    Route,
} from "react-router-dom";

import Login from "./containers/Login/Login";
import Replicas from "./containers/Replicas/Replicas";
import MainLayout from "./containers/MainLayout/MainLayout";
import Narratives from "./containers/Narratives/Narratives";
import Narrative from "./containers/Narratives/Narrative";
import Replica from "./containers/Replicas/Replica";
import {ProtectedRoute} from "./contexts/ProtectedRoute";
import AllNarratives from "./containers/Narratives/AllNarratives";

const Router: React.FC = () => {

    return (
        <Suspense fallback={null}>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                <Route path='/' element={<MainLayout />}>

                    <Route path='/' element={<ProtectedRoute/>}>
                        <Route path='/' element={<Narratives/>}/>
                    </Route>

                    <Route path='/narratives' element={<ProtectedRoute/>}>
                        <Route path='/narratives' element={<Narratives/>}/>
                    </Route>

                    <Route path='/narratives/allNarratives' element={<ProtectedRoute/>}>
                        <Route path='/narratives/allNarratives' element={<AllNarratives/>}/>
                    </Route>

                    <Route path='/narratives/:narrativeId' element={<ProtectedRoute/>}>
                        <Route path={'/narratives/:narrativeId'} element={<Narrative />} />
                    </Route>

                    <Route path='/replicas' element={<ProtectedRoute/>}>
                        <Route path={'/replicas'} element={<Replicas />}/>
                    </Route>

                    <Route path='/replicas/:replicaId' element={<ProtectedRoute/>}>
                        <Route path={'/replicas/:replicaId'} element={<Replica />}/>
                    </Route>

                    <Route path='/profile' element={<ProtectedRoute/>}>
                        <Route path={'/profile'} element={null}/>
                    </Route>

                    <Route path='/billing' element={<ProtectedRoute/>}>
                        <Route path={'/billing'} element={null}/>
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    );
};

export default Router

// <Route path="*" element={<NoMatch />} />
