import React from "react";
import './SearchBar.css'
import Images from "../../assets/Images";
import styled from "styled-components";

const SearchContainer = styled.div `
    display: flex;
    border: 1px solid;
    justify-content: start;
    align-items: center;
    padding: 10px 16px;
    min-width: 295px;
    height: 44px;
    border: 1px solid #C1CEDB;
    box-sizing: border-box;
    border-radius: 4px;
`

interface SearchInterface {
    customStyles?: object
}

const SearchBar = ({ customStyles }: SearchInterface) => {
    return (
        <SearchContainer style={customStyles}>
            <div className={'iconContainer'} >
                <img src={Images.icons.MagnifyingGlass} className={'icon'} />
            </div>
            <input type={'text'} placeholder="Search" className={'input'} />
        </SearchContainer>
    )
}

export default SearchBar
