import React from "react";
import {BrowserRouter} from "react-router-dom";
import Router from "./Router";
import {AuthContextProvider} from "./contexts/AuthContext";
// import {AuthContextProvider} from "./contexts/AuthContext";
// import {DataContextProvider} from "./contexts/DataContext";

const App: React.FC = () => {
  return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME || ''}>
          <AuthContextProvider>
              <Router />
          </AuthContextProvider>
      </BrowserRouter>
  );
};

export default App
