import React from "react";
import Images from "../../assets/Images";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const LinkContainer = styled.div `
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
    
    .icon {
        height: 30px;
        width: 30px;
    }
    
`

interface SearchInterface {
    customStyles?: object,
    linkTo: string,
    label: string,
    iconLeft?: string,
    iconRight?: string,
}

const LinkButton = ({ customStyles, label, linkTo, iconRight, iconLeft }: SearchInterface) => {
    let navigate = useNavigate();
    // @ts-ignore
    const rightIcon = Images.icons[iconRight]
    // @ts-ignore
    const leftIcon = Images.icons[iconLeft]

    return (
        <LinkContainer style={customStyles} onClick={() => navigate(linkTo)}>
            { iconLeft && <img src={leftIcon} className={'icon'} style={{marginRight: 10}}/> }
            <div> { label } </div>
            { iconRight && <img src={rightIcon} className={'icon'} style={{marginLeft: 10}}/>}
        </LinkContainer>
    )
}

export default LinkButton
