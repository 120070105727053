import { debug } from 'console';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';

import styled from 'styled-components';
import ChartContainer from './Charts/ChartContainer';
import ChartControls from './Charts/ChartControls';

import Prices, { ChartComponent } from './Charts/Prices';
import Tones from './Charts/Tones';
import LabelsList, { Wrapper as LabelsListWrapper} from './LabelsList';

interface Props {
  colors: string[];
  showTogglesControl: boolean;
  showComponentLabels: boolean;
}

const LabelTitle = styled.div`
  margin-bottom: 18px;
`

const NAMES = [
  "Bitcoin",
  "Ethereum",
  "Tether",
  "USD Coin",
  "Binance Coin",
  "Binance USD",
  "Cardano",
  "XRP",
  "Solana",
  "Dogecoin",
]

const ChartSection = ({ colors, showTogglesControl = false, showComponentLabels = true }: Props) => {
  const components = colors.map((color: string, i: number) => ({
    label: NAMES[i],
    color: color
  })) as ChartComponent[];

  const [enabledComponents, setEnabledComponents] = useState<ChartComponent[]>([components[0]]);

  const onLabelToggle = useCallback((component: ChartComponent) => {
    return (isActive: boolean) => {
      isActive
        ? setEnabledComponents([...enabledComponents, component])
        : setEnabledComponents(enabledComponents.filter((v) => v.label !== component.label))
    }
  }, [enabledComponents]);

  if (components.length == 0 || enabledComponents.length == 0) {
    return <></>;
  }

  return (
    <Wrapper>
      { showComponentLabels && (
          <>
            <LabelTitle>Select assets to visualize</LabelTitle>
            <LabelsList
              labels={
                components.map((component: ChartComponent, i: number) => ({
                  isActiveByDefault: i === 0,
                  text: component.label,
                  color: component.color,
                  onToggle: onLabelToggle(component)
                }))
              } 
            />
          </>
        )
      }
      <ChartContainer>
        <ChartControls showTogglesControl={showTogglesControl} />
        <Prices showConfidenceInterval={false} showHistoricalForecasts={true} components={enabledComponents} />
      </ChartContainer>
      <ChartContainer>
        <Tones components={enabledComponents} />
      </ChartContainer>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  ${LabelsListWrapper} {
    margin-bottom: 20px;
  }
`;

export default ChartSection;
