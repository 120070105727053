import styled from 'styled-components';

import TimeControl from './TimeControl'
import ZoomControl from './ZoomControl';
import TogglesControl from './TogglesControl';


const StyledChartControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
`;

interface Props {
  showTogglesControl: boolean;
}

const ChartControls = ({ showTogglesControl = false }: Props) => {
  return (
    <StyledChartControls>
      <TimeControl />
      { showTogglesControl && <TogglesControl /> }
      <ZoomControl />
    </StyledChartControls>
  )
};

export default ChartControls;