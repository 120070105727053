import { HTMLAttributes } from 'react';

import styled from 'styled-components';


interface RadioButtonProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  groupName: string;
}

const StyledRadioButton = styled.label`
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  width: 84px;

  input {
    display: none;
  }

  span {
    color: #E5E5E5;
  }

  input[type="radio"]:checked + span {
    display: inline-block;
    border-radius: 10px;
    background-color: rgba(62, 69, 110, 1);
    color: #14E1E1;
    padding: 2px 32px;
    transition: background-color 300ms ease;
  } 
`;

const RadioButton = ({
  text,
  groupName,
  ...rest
}: RadioButtonProps) => (
  <StyledRadioButton>
    <input type="radio" id={text} name={groupName} />
    <span>{text}</span>
  </StyledRadioButton>
)

export default RadioButton;