const Colors = {
    sidebar: {
        background: '#080F14',
        contactSupportBackground: '#14E1E133',
        selectedText: '#14E1E1',
        text: '#E5E5E5',
        iconSelectedBkg: '#14E1E1',
        iconBkg: '#14E1E133',
    },
    buttons: {
        border: '#14E1E1',
        text: '#E5E5E5',
        primaryBackground: '#14E1E133',
        secondaryBackground: '#064B4B33'
    },
    fonts: {
        title: '',
        subTitle: '#5A6666',
    },
    cards: {
        replica: {
            narrativeTitle: '#979797'
        }
    }
};

export default Colors;
