import { useState, useCallback } from "react";
import styled from "styled-components";

import Plus from '../../assets/images/icons/Plus.svg';

export interface Props {
  text: string;
  color: string;
  isActiveByDefault: boolean;
  onToggle: (active: boolean) => void;
}

const Label = ({ text, color, isActiveByDefault=false, onToggle=(() => null) }: Props) => {
  const [isActive, setActive] = useState<boolean>(isActiveByDefault);

  const onClickToggler = (e: React.MouseEvent<HTMLElement>) => {
    const updatedIsActive = !isActive;

    onToggle(updatedIsActive)
    setActive(updatedIsActive);
  };

  return (
    <Wrapper
      onClick={onClickToggler}
      className={isActive ? "active" : "disabled"}
      labelColor={color}
    >
      <span>{text}</span>
      <img src={Plus} width={16}/>
    </Wrapper>
  );
};

interface WrapperProps {
  labelColor: string;
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 16px;
  border-radius: 100px;
  position: relative;

  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: ClashDisplay;
  letter-spacing: 2px;
  height: 28px;

  transition: background ease-out .2s, color ease-out .2s;

  &.disabled {
    color: rgba(255, 255, 255, 1);  
    background: rgba(6, 75, 75, 0.4);
  }

  &.active {
    background: ${(p: WrapperProps) => p.labelColor};
    color: rgba(255, 255, 255, 1);  

    &:before {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -1px;
      border-radius: inherit;
      background: linear-gradient(to right, rgba(250, 250, 250, 1), rgba(255, 255, 255, 0));
    }

    img {
      transform: rotate(45deg);
    }
  }

  label {
    text-transform: uppercase;
  }

  span {
    white-space: nowrap;
  }

  input {
    display: none;
  }

  img {
    transition: transform ease-out .2s;
    stroke: white;
  }
`;

export default Label;