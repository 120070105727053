import data from './narratives.json';

const DataService = (() => {
    // const BASE_URL = process.env.REACT_APP_API_ROOT;
    const BASE_URL = '';

    const requestHeaders = (token: string) => ({ headers: {'Authorization': 'Bearer '+ token, 'Content-Type': 'application/json' } })

    const fetchNarratives = async (token: string) => {
        const url = `${BASE_URL}/narratives`;

        return fetch(url, requestHeaders(token))
            .then((response) => response.json())
    };

    const fetchNarrativesMock = async () => data;

    return {
        fetchNarratives: () => fetchNarratives("TOKEN"),
        fetchNarrativesMock: () => fetchNarrativesMock()
    }
})();

export default DataService;
