interface Props {
  type: 'left' | 'right';
}

const ArrowLeft = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#FFFFFF" stroke-miterlimit="10"/>
    <path d="M15.2426 20.2426L11 16L15.2426 11.7574" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 16H21" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

const ArrowRight = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#FFFFFF" stroke-miterlimit="10"/>
    <path d="M16.7574 20.2426L21 16L16.7574 11.7574" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 16H21" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

const ArrowSlider = ({ type }: Props) => {
  return type == 'left' ? <ArrowLeft /> : <ArrowRight />;
}

export default ArrowSlider;