import styled from 'styled-components';

import ZoomIn from '../../../assets/images/icons/Zoom.svg';
import ZoomOut from '../../../assets/images/icons/ZoomOut.svg';

const ZoomControl = ({}) => {
  return (
    <Wrapper>
      <button>
        <img src={ZoomIn} />
      </button>
      <button>
        <img src={ZoomOut} />
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;

export default ZoomControl;