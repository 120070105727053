import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import styled from "styled-components";
import Colors from "../../assets/Colors";
import SidebarButton from "../../components/Sidebar/SidebarButton";
import Images from "../../assets/Images";
import Button from "../../components/Buttons/Button";
import Auth from "../../contexts/Auth";
import {useNavigate} from "react-router-dom";

const SidebarContainer = styled.div`
    color: white;
    background-color: ${Colors.sidebar.background};
    display: flex;
    flex-direction: column;
    min-width: 320px;
    height: 100vh;
    padding: 48px 18px;
    box-sizing: border-box;
    box-shadow: 4px 0px 31px rgba(0, 0, 0, 0.33);
    
    .replicaTitle {
        display: flex;
        justify-content: center;
        font-size: 50px;
        font-family: monospace;
    }
`

const ButtonContainer = styled.div `
    flex: 3;
    padding-bottom: 24px;
    color: ${Colors.sidebar.selectedText};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #080F14 0%, #122226 100%);
    border-radius: 10px;
    margin-left: 18px;
    margin-right: 18px;
    justify-content: flex-end;
`

// TODO extract this into a constant file
const sidebarNavItems = ['narratives', 'replicas', 'profile', 'billing']

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        // const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        const activeItem = sidebarNavItems.findIndex(item => item === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    const logOut = () => {
        Auth.logout(() => navigate('/login'))
    }
    return (
        <SidebarContainer>
            <div className={'replicaTitle'} style={{marginBottom: 30}}>
                <img src={Images.logo} alt="Logo" className={''} />
            </div>
            <div style={{flex: 3, paddingTop: 100}}>
                <SidebarButton linkTo={'/narratives'} text={'Narratives'} indexSelected={activeIndex} indexId={0} icon={'Hexagon'}/>
                <SidebarButton linkTo={'/replicas'} text={'Replicas'} indexSelected={activeIndex} indexId={1} icon={'Briefcase'}/>
                <SidebarButton linkTo={'/profile'} text={'My Profile'} indexSelected={activeIndex} indexId={2} icon={'User'}/>
                <SidebarButton linkTo={'/billing'} text={'Billing'} indexSelected={activeIndex} indexId={3} icon={'Wallet'}/>
            </div>
            <ButtonContainer>
                <div style={{marginBottom: 30}}>
                    <img src={Images.logoutImage} alt="Logo" className={''} />
                </div>
                <Button variant="primary" label={'Contact Support'} customStyle={{ marginBottom: 15 }}/>
                <Button variant="secondary" label={'Logout'} focused onClick={logOut}/>
            </ButtonContainer>
        </SidebarContainer>
    );
};

export default Sidebar;
