import { Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface ChartComponent {
  label: string;
  color: string;
}

interface ToneDataPoint {
  measuredAt: Date;
  [key: string]: any;
}

const generateDataPoints = (components: ChartComponent[]): ToneDataPoint[] => Array
  .from({ length: 60 })
  .reduce((prev: ToneDataPoint[], curr, currIdx) => {
    const firstDate = new Date("2022-01-01");

    let measuredAt = new Date(firstDate);
    measuredAt.setDate(measuredAt.getDate() + currIdx);

    const componentTones = components.reduce((obj, component) => {
      const prevToneValue = currIdx == 0
        ? Math.random() * 10 - 5
        : prev[prev.length - 1][component.label.replace(/\s+/g, '-')];

      obj[component.label.replace(/\s+/g, '-')] = measuredAt <= new Date("2022-02-01") 
        ? prevToneValue + Math.random() * 10 - 5
        : 0;

      return obj;
    }, {} as { [key: string]: number });

    const componentColors = components.reduce((obj, component) => {
      obj[component.label.replace(/\s+/g, '-') + '-color'] = component.color;

      return obj;
    }, {} as { [key: string]: string });

    const newPoint: ToneDataPoint = { measuredAt, ...componentTones, ...componentColors };
    
    return [...prev, newPoint];
  }, []);

const CustomTooltip = ({ active, payload, label }: { active?: any, payload?: any, label?: any }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          background: '#272B3F',
          width: 115,
          padding: 12,
          borderRadius: 2,
          backdropFilter: 'blur(2px)',
        }}
      >
        {payload.map((p: any) => (
          <span
            className="label"
            style={{ 
              display: 'block', 
              color: p.payload[`${p.name}-color`], // Find a way to not pass color per each data point
              letterSpacing: 2
            }}
          >
            {`${parseFloat(p.value).toFixed(2)}`}
          </span>
        ))}
        <span
          style={{
            color: 'white',
            fontSize: 8
          }}
        >
          {new Date(payload[0].payload.measuredAt).toLocaleString('default', { month: 'short', day: "2-digit", year: 'numeric'})}
        </span>
      </div>
    );
  }

  return null;
};
  
interface Props {
  components: ChartComponent[];
}

const Tones = ({ components }: Props) => {
  if (components.length == 0 || components[0] === undefined) {
    return <></>;
  }

  const data = generateDataPoints(components);

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <ResponsiveContainer>
        <BarChart
          width={600}
          height={400}
          data={data}
          stackOffset="sign"
          maxBarSize={12}
        >
          <defs>
            { components.map((component, index) => (
              <linearGradient id={`bar-${component.label.replace(/\s+/g, '-')}`} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor={component.color} />
                <stop offset="100%" stopColor={component.color} stopOpacity={0.7}/>
              </linearGradient>
            
            ))}
          </defs>

          <XAxis
            dataKey="measuredAt"
            axisLine={false}
            tickLine={false}
            tickFormatter={(tick: Date) => new Date(tick).toLocaleString('default', { month: 'short', day: "2-digit" }) }
          />

          <YAxis
            axisLine={false}
            tickLine={false}
            tickMargin={24}
          />

          <Tooltip content={<CustomTooltip />} />

          { components.map((component) => (
              <Bar
                dataKey={component.label.replace(/\s+/g, '-')}
                stackId={'tones'}
                color={component.color}
                fill={`url(#bar-${component.label.replace(/\s+/g, '-')})`}
              />
          ))}

          <ReferenceLine
            y={0}
            stroke="rgba(255, 255, 255, 0.6)"
            strokeDasharray={"2 2"}
          />

        </BarChart>
      </ResponsiveContainer>
    </div>
  )
};

export default Tones;