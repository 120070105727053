import React, {useState} from "react";
import Container from "../../components/Container";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Buttons/Button";
import styled from "styled-components";
import Auth from "../../contexts/Auth";
import {useNavigate} from "react-router-dom";
import Images from "../../assets/Images";

const LoginContainer = styled.div `
    width: 400px;
    
    .title {
        margin-bottom: 50px;
        font-size: 36px;
    }
    
    .buttonContainer {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        align-items: end;
    }
    
    a {
        // all: unset;
        text-decoration: none;
        color: #14E1E1;
    }
`

const containerStyle = {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#080F14'
}

const Login: React.FC = () => {
    let navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const doLogin = () => {
        Auth.login(username, password, () => navigate('/'), rejectLogin)
    }

    const rejectLogin = () => {
        setError(true)
        setErrorMsg('Username or Password are incorrect')
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        doLogin();
    }

    return (
        <Container customStyles={containerStyle}>
            <LoginContainer>
                <form onSubmit={handleSubmit}>
                    <div className={'title'}> Log in into your Replica </div>
                    <TextField id="email" label="Username" customStyles={{ marginBottom: 20 }} value={username} onChange={setUsername} error={error}/>
                    <TextField id="password" label="Password" type={'password'} value={password} onChange={setPassword} errorMsg={errorMsg} error={error}/>
                    <div className={'buttonContainer'}>
                        <div style={{ flex: 4 }}> Forgot your password? </div>
                        <Button label={'Log in'} customStyle={{flex: 1}} onClick={doLogin} />
                    </div>
                    <div style={{marginTop: 40}}> Or <a href={''}> click here </a> to get access </div>
                </form>
            </LoginContainer>
            <img src={Images.login} style={{position: 'absolute', bottom: 0, right: 0}}/>
        </Container>
    );
};

export default Login
