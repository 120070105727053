import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import Auth from "./Auth";

export const ProtectedRoute = () => {
  const auth = Auth.isAuthenticated();
  // const auth = true
  // debugger

  return auth ? <Outlet /> : <Navigate to="/login" />;
}
