import React, {useEffect, useState} from "react";
import Container from "../../components/Container";
import Colors from "../../assets/Colors";
import SearchBar from "../../components/Search/SearchBar";
import NarrativeCard from "../../components/Cards/NarrativeCard";
import LinkButton from "../../components/Buttons/LinkButton";
import DataService from "../../services/DataService";
import OtherNarratives from "./OtherNarratives";
import CardGrid from "../shared/CardGrid";
import Narrative from "./common/interfaces";
import {NarrativeContainer, NarrativeHeader} from "./common/styledComponents";

const containerStyle = {
    color: 'white',
    display: 'flex',
    flexDirection: 'column'
}

const AvailableNarratives = ({ narratives } : { narratives: Narrative[] }) => {
    const header = (totalNarratives: number) => (
      <NarrativeHeader>
          <div style={{ fontSize: 20 }}> { `Available narratives (${totalNarratives})`}</div>
          <LinkButton linkTo={'/narratives/allNarratives'} label={'View all'} iconRight={'ArrowRight'}/>
      </NarrativeHeader>
    );

    const narrativeCards = narratives.slice(0, 4).map((narrative) => <NarrativeCard {...narrative}/>);

    return (
        <CardGrid
          minCardWidth={295}
          header={header(narratives.length)}
          cards={narrativeCards}
        />
    )
}

const Narratives: React.FC = () => {
    const [narratives, setNarratives] = useState<Narrative[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const narrativesData = await DataService.fetchNarrativesMock()
            setNarratives(narrativesData);
        };

        fetchData();
    }, [])

    return (
        <Container customStyles={containerStyle}>
            <NarrativeContainer>
                <div className={'headerContainer'}>
                    <div className={'titleContainer'}>
                        <div style={{ fontSize: 36 }}> Replica Narratives </div>
                        <div style={{ marginTop: 15, color: Colors.fonts.subTitle }}>
                            Replicas of share prices related to the selected narrative
                        </div>
                    </div>
                    <SearchBar/>
                </div>
                <AvailableNarratives narratives={narratives}/>
                <OtherNarratives />
            </NarrativeContainer>
        </Container>
    );
};

export default Narratives
