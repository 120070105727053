import styled from "styled-components";
import Heading from "../../../components/Heading";

interface Props {
  title: string;
  description: string;
}

const Title = styled.div`
  flex: 1 1 50%;
`;

const Description = styled.div`
  flex: 1 1 50%;
`;

const StyledChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 62px;
`;

const ChartHeader = ({title, description}: Props) => {
  return (
    <StyledChartHeader>
      <Title>
        <Heading text={title} tag="h3" />
      </Title>
      <Description>{description}</Description>
    </StyledChartHeader>
  );
}

export default ChartHeader