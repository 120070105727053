const Images = {
    background: require('./images/Background.png'),
    logoutImage: require('./images/Illustration.png'),
    logo: require('./images/Logo.png'),
    login: require('./images/login.png'),
    icons: {
        ArrowRight: require('./images/icons/ArrowRight.png'),
        ArrowLeft: require('./images/icons/ArrowLeft.png'),
        ArrowDown: require('./images/icons/ArrowDown.png'),
        Briefcase: require('./images/icons/Briefcase.png'),
        Hexagon: require('./images/icons/Hexagon.png'),
        InfoCircle: require('./images/icons/InfoCircle.png'),
        MagnifyingGlass: require('./images/icons/MagnifyingGlass.png'),
        User: require('./images/icons/User.png'),
        Wallet: require('./images/icons/Wallet.png'),
    }
}

export default Images
