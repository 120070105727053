import styled from 'styled-components';

const StyledTogglesControl = styled.div`
  display: flex;
  flex-direction: row;
`;

const TogglesControl = ({}) => {
  return (
    <StyledTogglesControl>
      <div>
        <input type="checkbox" id="historicalForecasts" name="historicalForecasts" />
        <label htmlFor="historicalForecasts">Historical Forecasts</label>
      </div>
      <div>
        <input type="checkbox" id="confidenceInterval" name="confidenceInterval" />
        <label htmlFor="confidenceInterval">Confidence interval</label>
      </div>
    </StyledTogglesControl>
  );
};

export default TogglesControl;
