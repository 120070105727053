import LinkButton from "../../components/Buttons/LinkButton";
import NarrativeCard from "../../components/Cards/NarrativeCard";
import React from "react";
import CardGrid from "../shared/CardGrid";

const OtherNarratives = () => {
    const header = (
      <div style={{marginTop: 56, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ fontSize: 20 }}> Other narratives </div>
          <LinkButton linkTo={''} label={'Request access'} iconRight={'ArrowRight'} />
      </div>
    );

    const narrativeCards = [
      <NarrativeCard privated linkTo={'NarrativeLink'} label={'label'} code={'/00-3/'} title={'Narrative name'} description={'Dictum in facilisis vulputate ac nibh scelerisque en et gravida sed cras.'} date={'Mar 16, 2021 4:51 pm'}/>,
      <NarrativeCard privated linkTo={'NarrativeLink'} label={'label'} code={'/00-3/'} title={'Narrative name'} description={'Dictum in facilisis vulputate ac nibh scelerisque en et gravida sed cras.'} date={'Mar 16, 2021 4:51 pm'}/>,
      <NarrativeCard privated linkTo={'NarrativeLink'} label={'label'} code={'/00-3/'} title={'Narrative name'} description={'Dictum in facilisis vulputate ac nibh scelerisque en et gravida sed cras.'} date={'Mar 16, 2021 4:51 pm'}/>,
      <NarrativeCard privated linkTo={'NarrativeLink'} label={'label'} code={'/00-3/'} title={'Narrative name'} description={'Dictum in facilisis vulputate ac nibh scelerisque en et gravida sed cras.'} date={'Mar 16, 2021 4:51 pm'}/>
    ]
    return (
      <CardGrid
        minCardWidth={295}
        header={header}
        cards={narrativeCards}
      />
    )
}

export default OtherNarratives;
