import Select from "react-select";
import React from "react";
import styled from "styled-components";

const CustomSelectContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    .title {
        margin-right: 10px;
    }
`


interface SelectProps {
    title?: string,
    label: string
}

const CustomSelect = ({ label, title } : SelectProps) => {
    const options = [
            { value: 'ukraine-war', label: 'Ukraine War' },
            { value: 'cryptos', label: 'Cryptos' },
            { value: 'insurance', label: 'Insurance' }
        ]

    const customStyles = {
        menu: (provided: any, state: any) => {
            // Options menu
            return { ...provided, marginTop: 0, border: '1px #14E1E1', borderStyle: 'none solid solid', borderRadius: '0px 0px 10px 10px', backgroundColor: '#080F1499'};
        },
        container: (provided: any, state: any) => {
            return { ...provided, minWidth: 150 }
        },
        control: (provided: any, state: any) => {
            // NARRATIVES CONTAINER
            let borderColor = state.isFocused ? '#14E1E1' : '#C1CEDB'
            let border = `1px ${borderColor}`
            let borderStyle = state.isFocused ? 'solid solid none solid !important' : 'solid !important';
            let borderRadius = state.isFocused ? '10px 10px 0px 0px' : '10px';

            return { ...provided, '&:hover': {}, backgroundColor: '#080F1499', transition: 'none', border: border, borderStyle: borderStyle, borderRadius: borderRadius, boxShadow: 'none'};
        },
        option: (provided: any, state: any) => {
            let fontColor = state.isFocused ? '#14E1E1' : '#C1CEDB';
            let bckgColor = state.isFocused ? '#076767' : 'transparent';

            return { ...provided, color: fontColor, backgroundColor: bckgColor };
        },
        placeholder: (provided: any, state: any) => {
            // NARRATIVES text
            let fontColor = state.isFocused ? '#14E1E1' : '#C1CEDB'
            return { ...provided, color: fontColor }
        },
        singleValue: (provided: any, state: any) => {
            return { ...provided, color: '#C1CEDB' }
        },
        indicatorSeparator: (provided: any, state: any) => {
            return {}
        },
        valueContainer:  (provided: any, state: any) => {
            return { ...provided}
        }
    }

    return (
        <CustomSelectContainer>
            { title && <div className={'title'}> { title } </div> }
            <Select
                options={options}
                styles={customStyles}
                placeholder={label}
                closeMenuOnSelect
                blurInputOnSelect
            />
        </CustomSelectContainer>

    )
}

export default CustomSelect
