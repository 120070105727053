// import jwt_decode from "jwt-decode";

class Auth {
    constructor() {
        this.jwt = localStorage.getItem('jwt');
        // this.expiration = this.jwt ? jwt_decode(this.jwt).exp : 0;
        this.expiration = this.jwt ? 5654283757 : 0;

        if(this.jwt && Math.floor(new Date().getTime()/1000) < this.expiration){
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
    }

    login(callback) {
        this.authenticated = true;
        callback();
    }

    logout(callback) {
        this.authenticated = false;
        this.jwt = null;
        localStorage.removeItem('jwt')
        localStorage.removeItem('username')
        // clearJwt();
        callback();
    }

    isAuthenticated() {
        return this.authenticated;
    }

    login(username, password, callback, rejectCallback) {
        const allowedUsers = {
          admin: 'admin',
          'x-trader@x-trader.net': 'GetReplica2022!'
        };

        if (allowedUsers[username] === password) {
            this.authenticated = true;
            localStorage.setItem('jwt', 'jwt');
            localStorage.setItem('username', username);
            callback()
        } else {
            this.authenticated = false;
            rejectCallback()
        }
    }
}

export default new Auth();
