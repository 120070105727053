import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import SearchBar from "../../components/Search/SearchBar";
import NarrativeCard from "../../components/Cards/NarrativeCard";
import DataService from "../../services/DataService";
import CardGrid from "../shared/CardGrid";
import LinkButton from "../../components/Buttons/LinkButton";
import {NarrativeContainer, NarrativeHeader } from "./common/styledComponents";
import Narrative from "./common/interfaces";

const containerStyle = {
    color: 'white',
    display: 'flex',
    flexDirection: 'column'
}

const AvailableNarratives = ({ narratives } : { narratives: Narrative[] }) => {
    const header = (totalNarratives: number) => (
        <NarrativeHeader>
            <div style={{ fontSize: 20 }}> { `Available narratives (${totalNarratives})`}</div>
        </NarrativeHeader>
    );

    const narrativeCards = narratives.map((narrative) => <NarrativeCard {...narrative}/>);

    return (
        <CardGrid
            minCardWidth={295}
            header={header(narratives.length)}
            cards={narrativeCards}
        />
    )
}

const AllNarratives: React.FC = () => {
    const [narratives, setNarratives] = useState<Narrative[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const narrativesData = await DataService.fetchNarrativesMock()
            setNarratives(narrativesData);
        };

        fetchData();
    }, [])

    return (
        <Container customStyles={containerStyle}>
            <NarrativeContainer>
                <div className={'headerContainer'}>
                    <LinkButton linkTo={'/narratives'} label={'Back'} iconLeft={'ArrowLeft'}/>
                    <SearchBar/>
                </div>
                <AvailableNarratives narratives={narratives}/>
            </NarrativeContainer>
        </Container>
    );
};

export default AllNarratives
