import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../Sidebar/Sidebar";

import BackgroundImage from "../../assets/images/Background.png";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-cover: content-box;
  background-position: center;
  background-color: #080F14;
  z-index: -1;
`

const AppLayout = () => {
    return(
        <StyledLayout>
            <Background />
            <Sidebar />
            <Outlet />
        </StyledLayout>
    )
};

export default AppLayout;
