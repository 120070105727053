import { ReactNode } from "react";
import styled from "styled-components";

interface BodyProps {
  minCardWidth: number;
  rowGap?: string | number;
  columnGap?: string | number;
}

interface GridProps {
  header: ReactNode;
  cards: ReactNode[];
}

type Props = GridProps & BodyProps;

const Header = styled.div`
  margin-bottom: 40px;
`;

const Body = styled.div<BodyProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${props => props.minCardWidth}px, 1fr));
  row-gap: ${props => props.rowGap || "20px"};
  column-gap: ${props => props.columnGap || "24px"};
`;

const CardGrid = (props: Props) => {
  const { header } = props;

  return (
    <div>
      <Header>
        {header}
      </Header>
      <Body {...props}>
        {props.cards}
      </Body>
    </div>
  )
};

export default CardGrid;