import styled from "styled-components";

type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface Props {
  text: string;
  tag: HeadingTag;
}

const StyledHeading = styled.h1`
  margin: 0;
`;

const styleProps: Record<HeadingTag, any> = {
  h1: {},
  h2: {},
  h3: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: 1.21,
    fontFamily: 'ClashDisplay'
  },
  h4: {},
  h5: {},
  h6: {}
};

const Heading = ({text, tag}: Props) => {
  const props = styleProps[tag];

  return (
    <StyledHeading as={tag} style={props}>
      {text}
    </StyledHeading>
  );
};

export default Heading;