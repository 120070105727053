import RadioButton from "../../RadioButton";

const TimeControl = ({}) => {
  return (
    <div>
      <RadioButton text="1M" groupName="timeSelection" />
      <RadioButton text="6M" groupName="timeSelection" />
      <RadioButton text="1Y" groupName="timeSelection" />
      <RadioButton text="3Y" groupName="timeSelection" />
    </div>
  );
};


export default TimeControl;