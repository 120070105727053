import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CardContainer = styled.div`
    min-width: 295px;
    min-height: 215px;
    height: 215px;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
    cursor: pointer;
    background: linear-gradient(180.16deg, rgba(18, 206, 206, 0.09) 0.14%, rgba(20, 225, 225, 0) 154.8%);
    backdrop-filter: blur(8px);
    border-radius: 8px;

    &:hover {
        background: rgba(193, 206, 219, 0.1);
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &.private {
        pointer-events: none;
        opacity: 0.6;
        filter: blur(3px);
        background: linear-gradient(104.99deg, rgba(8, 15, 20, 0.7) -13.91%, #080F14 100.48%) !important;
    }

    .label {
       background: #096969;
       color: white;
       border-radius: 100px;
       padding: 5px 15px;
       transition: 0.3s;
    }

    &:hover .label {
        background: #14E1E1;
        box-shadow: 0px 4px 20px rgba(54, 205, 187, 0.3);
        color: black;
        transition: 0.5s;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .title {
        margin-top: 15px;
        font-size: 20px;
    }

    .description {
        margin-top: 8px;
    }

    .date {
        margin-top: 13px;
    }
`

interface CardProps {
    label: string,
    code?: string,
    title: string,
    linkTo: string,
    description?: string,
    date?: string,
    disabled?: boolean
    privated?: boolean
}

const NarrativeCard = ({label, code, title, description, date, linkTo, disabled = false, privated = false }: CardProps) => {
    let navigate = useNavigate();

    return (
        <CardContainer className={`${disabled && 'disabled'} ${privated && 'private'}`}
                       onClick={() => { navigate(`/narratives/${linkTo}`)}}>
            <div className={'header'}>
                <div className={'label'}>
                    { label.toUpperCase() }
                </div>
                <div>
                    { code }
                </div>
            </div>
            <div className={'title'}>
                { title }
            </div>
            <div className={'description'}>
                { description }
            </div>
            <div className={'date'}>
                { date }
            </div>
        </CardContainer>
    )
}

export default NarrativeCard
