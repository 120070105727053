import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Colors from "../../assets/Colors";
import styled from "styled-components";
import Images from "../../assets/Images";

const SidebarButtonContainer = styled(Link)`
    .sidebarButtonContainer {
        height: 54px;
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        border-radius: 8px;
        padding-left: 24px;
        margin-bottom: 24px;
    }

    .iconContainer {
        height: 30px;
        width: 30px;
        background-color: ${Colors.sidebar.iconBkg};
        border-radius: 8px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .icon {
        height: 20px;
        width: 20px;
        filter: invert(0%) saturate(0%) brightness(200%) contrast(150%);
    }
    
    .iconSelected {
        // background-color: ${Colors.sidebar.iconSelectedBkg};
        background: rgba(20, 225, 225, 0.2);
    }
    
    .disabled {
        pointer-events: none;
        opacity: 0.7;
    }
    
    &.disabled {
        pointer-events: none;
        opacity: 0.7;
    }
    
    .selected {
        background: linear-gradient(96.56deg, rgba(20, 225, 225, 0.8) -32.32%, rgba(90, 102, 102, 0) 107.57%);
        transition: background 0.5s linear;
        color: ${Colors.sidebar.selectedText};
    }
    
   .unSelected {
       transition: background 0.5s;
        color: ${Colors.sidebar.text};
   }
`

interface SidebarButtonProps {
    linkTo: string,
    text: string,
    customStyle?: object,
    indexSelected: number,
    indexId: number,
    icon?: string,
    disabled?: boolean
}

const SidebarButton = ({ linkTo, text, indexSelected, indexId, customStyle, icon, disabled }: SidebarButtonProps) => {
    const [isIndexSelected, setIsIndexSelected] = useState<boolean>(false);

    useEffect(() => {
        setIsIndexSelected((indexId === indexSelected))
    }, [indexId, indexSelected]);

    // @ts-ignore
    const SidebarIcon = Images.icons[icon]

    return (
        <SidebarButtonContainer to={linkTo} style={{textDecoration: 'none'}}
                                className={`${disabled && 'disabled'}`}>
            <div className={`sidebarButtonContainer ${disabled && 'disabled'} ${isIndexSelected && 'selected'} ${!isIndexSelected && 'unSelected'}`}>
                {
                    SidebarIcon &&
                    <div className={`iconContainer ${isIndexSelected && 'iconSelected'}`}>
                      <img src={SidebarIcon} className={'icon'} />
                    </div>
                }
                <div style={{flex: 3}}> { text } </div>
            </div>
        </SidebarButtonContainer>
    )
};

export default SidebarButton;
