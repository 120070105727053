import React, {useState} from "react";
import styled from "styled-components";
import Images from "../../assets/Images";

const TextFieldContainer = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: start;
    
    padding: 10px 16px;
    min-width: 295px;
    
    border: 1px solid #C1CEDB;
    box-sizing: border-box;
    border-radius: 4px;
    
    transition: all 0.3s;

    input {
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: hidden;
        background-color: transparent;
        font-size: 16px;
        
        padding-left: 0px;

        color: #979797;
        margin-top: 4px;
        
        transition: all 0.3s;
        cursor: pointer;
    }
    
    input:focus {
        outline: none;
        box-shadow: 2px 3px 20px 1px rgba(0, 0, 0, 0.3);
        color: white;
        caret-color: #14E1E1;
        
        transition: all 0.3s;
    }
   
    .label {
        font-size: 12px;
    }
    
    input:valid + .label {
        color: #14E1E1 !important;
    }
    
    &:focus-within .label {
        transition: all 0.3s;
        color: #14E1E1;
    }

    &:focus-within {
        border-radius: 8px;
        border: 1px solid #14E1E1;
        transition: all 0.3s;
    }
    
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    
    &.error {
        color: #E77A7A;
        border: 1px solid #E77A7A;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #080F14 inset !important;
    }
    input:-webkit-autofill{
        -webkit-text-fill-color: #979797 !important;
        font-size: 16px;
    }
`

const TextFieldWrapper = styled.div `
    display: flex;
    flex-direction: column;
    
    .error {
        color: #E77A7A;
    }
    
    .errorContainer {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }
    .icon {
        height: 13px;
        width: 13px;
    }
`

interface TextFieldInterface {
    id?: string,
    label: string,
    customStyles?: object,
    disabled?: boolean
    type?: string
    value?: string
    errorMsg?: string
    error?: boolean
    onChange?: (s: string) => void
}

const TextField = ({ customStyles, label, id, disabled, type = 'text', value = '', onChange = (a) => {}, errorMsg, error }: TextFieldInterface) => {
    // missing when input is filled

    return (
        <TextFieldWrapper style={customStyles}>
            <TextFieldContainer id={id} className={`${disabled && 'disabled'} ${error && 'error'}`}>
                <div className={'label'}> { label } </div>
                <input
                    type={type}
                    placeholder={label}
                    value={value}
                    onChange={ (e) => onChange(e.target.value) }
                    autoComplete="off"
                />
            </TextFieldContainer>
            { error && errorMsg &&
              <div className={'errorContainer'}>
                <img src={Images.icons.InfoCircle} className={'icon'} style={{marginRight: 10}}/>
                <div className={'error'}> { errorMsg } </div>
              </div>

            }
        </TextFieldWrapper>

    )
}

export default TextField
