import React from "react";
import Container from "../../components/Container";
import {useParams} from "react-router-dom";
import SearchBar from "../../components/Search/SearchBar";
import styled from "styled-components";
import LinkButton from "../../components/Buttons/LinkButton";
import ChartSection from "../../components/ChartSection";
import ChartHeader from "../shared/ChartHeader";

const containerStyle = {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 120,
    paddingRight: 100,
    overflow: 'scroll'
}

const HeaderContainer = styled.div`
    padding-top: 44px;
    margin-bottom: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .backContainer {
        display: flex;
        align-items: center;
    }

    .backContainer:hover {
        cursor: pointer;
    }
`

const ScrollContainer = styled.div`
    margin-bottom: 40px;
`

const Narrative: React.FC = () => {
    let { narrativeId } = useParams();
    // let invoice = useFakeFetch(`/api/narratives/${narrativeId}`);

    let title = 'Insurability of Systemic Risk'
    let description = 'Recently an array of seemingly unrelated risks are surfacing creating large stress for' +
        ' continental insurance companies as reflected in the mainstream media and specilalized sites';
    return (
        <Container customStyles={containerStyle}>
            <ScrollContainer>
                <HeaderContainer>
                    <LinkButton linkTo={'/narratives'} label={'Back'} iconLeft={'ArrowLeft'}/>
                    <SearchBar />
                </HeaderContainer>
                <ChartHeader
                    title={title}
                    description={description}
                />
                <ChartSection
                  colors={[
                    'rgba(13, 150, 150, 1)',
                    'rgba(198, 141, 255, 1)',
                    'rgba(133, 147, 255, 1)',
                    'rgba(231, 122, 122, 1)',
                    'rgba(233, 174, 120, 1)',
                    'rgba(187, 198, 0, 1)',
                    'rgba(233, 120, 201, 1)',
                    'rgba(122, 198, 231, 1)',
                    'rgba(121, 187, 148, 1)',
                    'rgba(136, 67, 190, 1)',
                  ]}
                  showTogglesControl={false}
                  showComponentLabels={true}
                />
            </ScrollContainer>
        </Container>
    );
};

export default Narrative
