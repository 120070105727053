import React from "react";
import styled from "styled-components";

const BackgroundContainer = styled.div `
    height: 100vh;
    flex: 1;
    // width: 100vw;
    font-family: "Source Sans Pro", sans-serif;
    position: relative;
    min-width: 750px;
        
    .circle {
        background: radial-gradient(#8593FF,#080F14 60%);
        filter: blur(150px);
        border-radius: 50%;
        box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);
        position: absolute;
    }
    
    .circle-1 {
        height: 600px;
        width: 600px;
        bottom: 0;
        left: 0;
    }
    
    .circle-2 {
        height: 200px;
        width: 200px;
        bottom: 10px;
        right: 50px;
    }
    
    .circle-3 {
        height: 400px;
        width: 400px;
        top: 0;
        right: 0;
    }
`;

interface ContainerInterface {
    children: React.ReactNode,
    customStyles?: object
}

const Container = ({ children, customStyles}: ContainerInterface) => {
    return (
        <BackgroundContainer id='Container' style={{...customStyles}} className={'box'}>
            { children }
        </BackgroundContainer>
    )
}

export default Container;
