import styled from 'styled-components';


const ChartContainer = styled.div`
  background: linear-gradient(90deg, #2C2C33B2, #1E1F2424);
  padding: 32px;
  margin-bottom: 24px;
  border-radius: 20px;
  box-shadow: 0px 4px 124px rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(34px);
`;

export default ChartContainer;