// @ts-nocheck
import React from "react";

import {useNavigate} from "react-router-dom";
import Container from "../../components/Container";
import Colors from "../../assets/Colors";
import SearchBar from "../../components/Search/SearchBar";
import styled from "styled-components";
import ReplicaCard from "../../components/Cards/ReplicaCard";
import CustomSelect from "../../components/Select/CustomSelect";
import CardGrid from "../shared/CardGrid";

const ReplicasContainer = styled.div`
    padding-left: 120px;
    padding-right: 100px;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    
    .headerContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .titleContainer {
        display: flex;
        flex-direction: column; 
    }
      
   .scrollContainer {
        height: 70vh;
        overflow-y: scroll;
   }
`

const containerStyle = {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
}

const Replicas: React.FC = () => {
    let navigate = useNavigate();

    const header = (
      <div style={{marginTop: 56, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ fontSize: 20 }}> Components </div>
          <div style={{ minWidth: 180}}>
              <CustomSelect title={'Filter by'} label={'Narratives'}/>
          </div>
      </div>
    );

    const cards = [
      <ReplicaCard componentName={'Bitcoin'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'Ethereum'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'Tether'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'USD Coin'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'Binance Coin'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'Binance USD'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'Cardano'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
      <ReplicaCard componentName={'XRP'} narrativeTitle={'narrative name'} linkTo={'Replica1'} />,
    ]

    return (
        <Container customStyles={containerStyle}>
            <ReplicasContainer>
                <div className={'headerContainer'}>
                    <div className={'titleContainer'}>
                        <div style={{ fontSize: 36 }}> Replicas </div>
                        <div style={{ marginTop: 15, color: Colors.fonts.subTitle }}>
                            Replicas of share prices related to the selected narrative
                        </div>
                    </div>
                    <SearchBar/>
                </div>
                
                <CardGrid
                    minCardWidth={295}
                    header={header}
                    cards={cards}
                />
            </ReplicasContainer>
        </Container>
    );
};

export default Replicas
