import styled from "styled-components";

const NarrativeContainer = styled.div`
    padding-left: 120px;
    padding-right: 100px;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    
    .headerContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .titleContainer {
        display: flex;
        flex-direction: column; 
    }
   
   .allNarrativesContainer {
        height: 70vh;
        overflow-y: scroll;
   }
`

const NarrativeHeader = styled.div `
    margin-top: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
`

export { NarrativeContainer, NarrativeHeader }
