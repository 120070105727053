import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Colors from "../../assets/Colors";

const ReplicaCardContainer = styled.div`
    min-width: 295px;
    min-height: 100px;
    height: 100px;
    padding-left: 24px;
    flex: 1;
    display: flex;
    justify-content: center;
        
    flex-direction: column;
    box-sizing: border-box;
    cursor: pointer;
    background: linear-gradient(180.16deg, rgba(18, 206, 206, 0.09) 0.14%, rgba(20, 225, 225, 0) 154.8%);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    
    &:hover {
        background: rgba(193, 206, 219, 0.1);
    }
    
    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
    
    .title {
        font-size: 20px;
    }
    
    .description {
        margin-top: 8px;
        font-size: 15px;
        color: ${Colors.cards.replica.narrativeTitle};
    }
`

interface CardProps {
    componentName: string,
    narrativeTitle: string,
    linkTo: string,
    disabled?: boolean
}

const ReplicaCard = ({componentName, narrativeTitle, linkTo, disabled = false }: CardProps) => {
    let navigate = useNavigate();

    return (
        <ReplicaCardContainer className={`${disabled && 'disabled'}`}
                       onClick={() => { navigate(`/replicas/${linkTo}`)}}>
            <div className={'title'}>
                { componentName }
            </div>
            <div className={'description'}>
                { narrativeTitle }
            </div>
        </ReplicaCardContainer>
    )
}

export default ReplicaCard
