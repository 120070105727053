import styled from "styled-components";
import Colors from "../../assets/Colors";
import Images from "../../assets/Images";


const ButtonDiv = styled.button`
    all: unset;
    background-color: ${Colors.buttons.primaryBackground};
    height: 40px;
    min-width: 187px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    
    display: flex;
    justify-content: space-around;
    transition: 0.3s;
    
    &:hover {
        background: rgba(20, 225, 225, 0.4);
        box-shadow: 0px 0px 16px rgba(20, 225, 225, 0.6);
        backdrop-filter: blur(4px);
        border-radius: 4px;
    }
    
    &:active { 
        color: #064B4B;
        background: #14E1E1;
        backdrop-filter: blur(4px);
        border-radius: 4px;
    }

    &:disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.7;
    }
    
    .icon {
        height: 30px;
        width: 30px;
        transition: all 0.3s;
    }
    
    &.focused {
        border-color: ${Colors.buttons.border};
        border: 1px solid;
    }
    
    &.focused:active { 
        color: #14E1E1;
        background-color: #0b3b3a;
    }
    
    &.secondary {
        background-color: ${Colors.buttons.secondaryBackground};
    }
    
    &.tertiary {
        background-color: transparent;
        border: none;
    }

    &.tertiary:hover {
        color: white;
        background: transparent;
        box-shadow: none;
        backdrop-filter: none;
        border-radius: 0px;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: white;
        transition: all 0.3s;
    }
`

interface ButtonProps {
    label: string,
    customStyle?: object,
    onClick?: () => void,
    focused?: boolean,
    disabled?: boolean,
    variant?: 'primary' | 'secondary' | 'tertiary',
    iconLeft?: string,
    iconLeftStyle?: object,
    iconRightStyle?: object,
    iconRight?: string,
}


const Button = ({ label, customStyle, onClick, focused, variant = 'primary', iconRight, iconLeft, disabled, iconRightStyle, iconLeftStyle }: ButtonProps) => {
    // @ts-ignore
    const rightIcon = Images.icons[iconRight]
    // @ts-ignore
    const leftIcon = Images.icons[iconLeft]

    return (
        <ButtonDiv disabled={disabled} onClick={onClick}
                   style={{...customStyle}}
                   className={`${focused && 'focused'} ${variant}`}>
            { iconLeft && <img src={leftIcon} className={'icon'}  style={{ ...iconLeftStyle }}/> }
            <div> { label } </div>
            { iconRight && <img src={rightIcon} className={'icon'} style={{ ...iconRightStyle }}/>}
        </ButtonDiv>
    )
}

export default Button;
