import React from 'react';
import { Line, ComposedChart, XAxis, Area, ReferenceLine, CartesianGrid, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface HistoricDataPoint {
  measuredAt: number;
  historicPrice: number;
  forecastPrice?: number;
  forecastConfidenceInterval?: [number, number];
}

interface ForecastDataPoint {
  measuredAt: number;
  historicPrice?: number;
  forecastPrice: number;
  forecastConfidenceInterval: [number, number];
}

type PriceDataPoint = HistoricDataPoint | ForecastDataPoint;

const generateDataPoints = (): PriceDataPoint[] => Array.from({ length: 60 }).reduce((prev: PriceDataPoint[], curr, currIdx, arr) => {
  const firstDate = new Date("2022-01-01");
  let measuredAt = new Date(firstDate);
  measuredAt.setDate(measuredAt.getDate() + currIdx);

  const historicPrice = measuredAt <= new Date("2022-02-01")
    /* @ts-ignore */
    ? (currIdx == 0 ? Math.random() * 50 + 500 : prev[prev.length - 1].historicPrice + Math.random() * 50 - 25) // @ts-ignore
    : null;

  const CI_INTERVAL = 30;

  const forecastPrice = measuredAt >= new Date("2022-02-01")
    /* @ts-ignore */
    ? (prev[prev.length - 1].forecastPrice ? prev[prev.length - 1].forecastPrice + Math.random() * 50 - 25 : historicPrice)
    : null;

  const interval = Math.random() * CI_INTERVAL * Math.exp(currIdx / 30);
  const m = 3 * Math.exp(currIdx / 15);
  const forecastConfidenceInterval = measuredAt >= new Date("2022-02-01")
    /* @ts-ignore */
    ? [forecastPrice - interval - m, forecastPrice + interval + m]
    : null;

  /* @ts-ignore */
  const newPoint: PriceDataPoint = { historicPrice, measuredAt: measuredAt.getTime(), forecastPrice, forecastConfidenceInterval };
  
  return [...prev, newPoint];
}, []);

const generateForecastsData = (dates: Date[], startingAt: number) => {
  // @ts-ignore
  return dates.reduce((prev, curr, idx) => { 
    const measuredAt = curr.getTime();
    if (idx == 0) console.log(startingAt);
    // @ts-ignore
    const forecastPrice = (idx == 0 ? startingAt : prev[prev.length - 1].forecastPrice + Math.random() * 50 - 25);

    return [...prev, { measuredAt, forecastPrice }]
  }, [])
};

/* @ts-ignore */
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

/* @ts-ignore */
function getDates(startDate, stopDate) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate));
      currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

const createForecasts = (data: Map<string, PriceDataPoint[]>) => {
  // Assuming data contains only one label
  const label = Array.from(data.keys())[0];
  const dataPoints = data.get(label);

  if (!dataPoints)
    return [];

  getDates(new Date("2022-01-01"), new Date("2022-01-31"))
    .map((d: Date, idx) => {
      const startingAt = dataPoints[idx].measuredAt;

      return { key: d, data: generateForecastsData(getDates(d, new Date("2022-02-28")), startingAt) };
    }
  );
};

/* @ts-ignore */
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          background: '#272B3F',
          width: 115,
          padding: 12,
          borderRadius: 2,
          backdropFilter: 'blur(2px)',
        }}
      >
        {payload.map((p: any) => (
          <span
            className="label"
            style={{ 
              display: 'block', 
              color: p.color,
              letterSpacing: 2
            }}
          >
            {`$${parseFloat(p.value).toFixed(2)}`}
          </span>
        ))}
        <span
          style={{
            color: 'white',
            fontSize: 8
          }}
        >
          {new Date(payload[0].payload.measuredAt).toLocaleString('default', { month: 'short', day: "2-digit", year: 'numeric'})}
        </span>
      </div>
    );
  }

  return null;
};

const CustomCursor = (props: any) => {
  return <div>H</div>;
};

const ActiveDot = (props: any) => {
  if (!props.cy) {
    return null;
  }

  return (
    <g>
      <circle
        cx={props.cx}
        cy={props.cy}
        fill={props.fill.replace(/[^,]+(?=\))/, '0.3')}
        r={10}
        stroke={props.fill}
        strokeWidth={1}
      />
      <circle
        r={5}
        fill={props.fill}
        cx={props.cx}
        cy={props.cy}
      />
      {/* <rect
        fill='url(#dotLineGradient)'
        x={props.cx}
        y={props.cy > props.height / 2 ? props.cy + 10 : props.cy - 50}
        width={1}
        height={40}
      /> */}
    </g>
  );
}

export interface ChartComponent {
  label: string;
  color: string;
}

interface PricesProps {
  showHistoricalForecasts: boolean;
  showConfidenceInterval: boolean;
  components: ChartComponent[];
}

const Prices = ({ showHistoricalForecasts, showConfidenceInterval, components }: PricesProps) => {
  const data = components.reduce((prev, curr) => {
    prev.set(curr.label, generateDataPoints());

    return prev;
  }, new Map<string, PriceDataPoint[]>());

  const forecasts = createForecasts(data);

  console.log(forecasts);

  return (
    <div style={{ width: '100%', height: '430px' }}>
      <ResponsiveContainer>
        <ComposedChart
          width={600}
          height={400}
          margin={{ bottom: 40 }}
        >
          <defs>
            <linearGradient id="pastForecastGradient" gradientUnits='objectBoundingBox'>
              <stop offset="0%" stopColor="#14E1E1" />
              <stop offset="30%" stopColor="#8593FF" stopOpacity={0.6}/>
              <stop offset="100%" stopColor="#8593FF" stopOpacity={0.3}/>
            </linearGradient>
            <linearGradient id="abc" gradientUnits='userSpaceOnUse' x1='0%' x2='0%' y1="0%" y2="100%">
              <stop offset="0%" stopColor="#FFFFFF" stopOpacity={0}/>
              <stop offset="26%" stopColor="#FFFFFF" stopOpacity={0.3}/>
              <stop offset="74%" stopColor="#FFFFFF" stopOpacity={0.3}/>
              <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="dotLineGradient" gradientUnits='objectBoundingBox' x1='0%' x2='0%' y1="0%" y2="100%">
              <stop offset="0" stopColor="#080F14" stopOpacity={0} />
              <stop offset="1" stopColor="#12CECE" />
            </linearGradient>
          </defs>

          <CartesianGrid
            stroke='#FFFFFF33'
            strokeDasharray="2 2"
            vertical={false}
          />

          <XAxis
            allowDuplicatedCategory={false}
            axisLine={false}
            dataKey="measuredAt"
            tickFormatter={(tick: Date) => new Date(tick).toISOString().substring(0, 10)}
            tickLine={false}
            interval={5}
            tickMargin={40}
          />

          <YAxis
            axisLine={false}
            tickLine={false}
            textAnchor={'start'}
            tickMargin={50}
            tickFormatter={(tick: number) => `${tick}`}
          />

          { components.map((component: ChartComponent) => {
              const componentData = data.get(component.label);

              return (
                <>
                  { showConfidenceInterval &&
                    <Area
                      key={component.label + "-confidence-interval"}
                      activeDot={false}
                      dataKey="forecastConfidenceInterval"
                      stroke="none"
                      fill="#8593FF"
                      fillOpacity={0.4}
                      type="monotone"
                    />
                  }

                  <Line
                      key={component.label + "-historic-price"}
                    activeDot={<ActiveDot />}
                    data={componentData}
                    animationDuration={300}
                    dataKey="historicPrice"
                    dot={false}
                    stroke={component.color}
                    strokeWidth={1.5}
                    type="monotone"
                    shapeRendering={'geometricPrecision'}
                  />
        
                  <Line
                    key={component.label + "-forecast-price"}
                    activeDot={<ActiveDot />}
                    data={componentData}
                    animationBegin={300}
                    animationDuration={300}
                    dataKey="forecastPrice"
                    dot={false}
                    stroke={component.color}
                    strokeDasharray={"6 6"}
                    type="monotone"
                  />

                  {/* { showHistoricalForecasts && forecasts.map((forecast) => (
                    <Line
                      key={component.label + "-historical-forecast-prices"}
                      activeDot={null}
                      data={forecast.data}
                      dataKey="forecastPrice"
                      dot={false}
                      key={forecast.key}
                      name={forecast.key}
                      stroke="url(#pastForecastGradient)"
                      strokeOpacity={0.4}
                      type="monotone"
                      shapeRendering={'geometricPrecision'}
                      isAnimationActive={false}
                    />
                  ))} */}
                </>
              );
            })
          }

          <Tooltip
            cursor={<CustomCursor />}
            // @ts-ignore
            content={<CustomTooltip />}
          />

          <ReferenceLine
            x={new Date("2022-02-01").getTime()}
            stroke="url(#abc)"
            strokeDasharray={"4 4"}
          />

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Prices;