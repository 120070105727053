import React, {useState} from 'react';

const AuthContext = React.createContext({});

// @ts-ignore
const AuthContextProvider = ({ children }) => {
    const [token, setToken] = useState<string>('');

    return (
        <AuthContext.Provider
            value={{
                token,
                setToken
            }} >
            {children}
        </AuthContext.Provider>
    );
};

export {
    AuthContextProvider,
    AuthContext
}
